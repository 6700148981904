.section {
  @extend %section-padding;

  &__top {
    margin-bottom: 67px;

    @include xs {
      margin-bottom: 31px; } }

  &__block {
    margin-bottom: 81px;

    @include xs {
      margin-bottom: 31px; } }

  &__title-block {
    text-align: center;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px; }

  &__title {
    margin-bottom: 31px; }

  &__subttl {
    margin-bottom: 30px; }

  &__btn {
    text-align: center;
    padding-top: 100px;

    @include xl {
      padding-top: 80px; }

    @include lg {
      padding-top: 60px; } }

  &--dark {
    padding-top: 130px;
    padding-bottom: 130px;

    color: $white;
    background-color: $black-light;

    @include xl {
      padding-top: 100px;
      padding-bottom: 100px; }

    @include lg {
      padding-top: 70px;
      padding-bottom: 70px; }

    .title-block__upttl {
      color: rgba($white, .7); } }

  &--lightest {
    padding-top: 130px;
    padding-bottom: 130px;

    background-color: $lightest;

    @include xl {
      padding-top: 100px;
      padding-bottom: 100px; }

    @include lg {
      padding-top: 70px;
      padding-bottom: 70px; } }

  &--p-sm {
    @extend %section-padding-sm; }

  &--p-xs {
    @extend %section-padding-xs; } }

.section-top {
  @extend %row;

  &__title-block {
    width: percentage(6 / 12);

    @include lg {
      width: 100%; } }

  &__right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    padding-bottom: 5px;
    padding-right: 35px;

    width: percentage(6 / 12);

    @include lg {
      display: none; } } }

.section-block {
  @extend %row;

  padding-bottom: 84px;

  @include xl {
    padding-bottom: 51px; }

  @include lg {
    padding-bottom: 47px; }

  @include notfirst {
    padding-top: 84px;

    @include xl {
      padding-top: 51px; }

    @include lg {
      padding-top: 47px; } }

  &__title-block,
  &__content {
    width: 50%;

    @include lg {
      width: 100%; } }

  &__title-block {
    padding-right: 15px;
    @include xl {
      padding-right: 10px; }

    @include lg {
      padding-right: 0;
      margin-bottom: 27px; } }

  &__content {
    max-width: 657px;
    padding-left: 15px;
    padding-top: 54px;

    @include xl {
      padding-top: 40px;
      padding-left: 10px; }

    @include lg {
      padding-left: 0;
      padding-top: 0; }

    &--simple {
      padding-top: 0 !important; } }

  &__text {
    @extend %text;

    p {
      @include notlast {
        margin-bottom: 35px; } } }

  &--sm {
    padding-bottom: 56px;
    @include lg {
      padding-bottom: 47px; }

    @include notfirst {
      padding-top: 56px;
      @include lg {
        padding-top: 47px; } } } }
