.prev,
.next {
  @extend %flex-center;

  width: 52px;
  height: 52px;

  border-radius: 50%;
  border: 1px solid currentColor;

  @include xs {
    width: 45px;
    height: 45px; }

  @include before {
    @include icon-arrow(11, 11, 1, currentColor, 45, px);
    display: block;
    margin-right: -0.2em; } }

.next {
  transform: scaleX(-1); }

.gallery-item {
  display: block;
  font-size: 0;
  position: relative;
  padding-top: percentage(728 / 1617);

  @include xs {
    padding-top: percentage(348 / 382); }

  img {
    @extend %coverdiv;
    @extend %coverimage; } }

.slider {
  &__wrap {
    position: relative;
    * {
      box-sizing: border-box; }

    &:not([data-state="initialized"]) {
      .slider__controls,
      .slider__pagination {
        display: none; } } }

  &__controls {
    display: flex;
    align-items: center; }

  &__pagination {
    display: flex;
    align-items: center;
    font-size: 0;

    > span {
      font-size: 17px;
      letter-spacing: 0.15em;

      &:first-child {
        display: flex;
        align-items: center;
        @include after {
          display: block;
          height: 1px;
          width: 34px;
          background-color: currentColor;

          margin-left: 16px;
          margin-right: 16px; } } } }

  &__prev {
    margin-right: 29px;
    @include xl {
      margin-right: 22px; }

    @include xs {
      margin-right: 20px; } }

  &--hero {
    &__wrap {
      color: $white;
      background-color: $black;

      > .container {
        position: relative; }

      .swiper-slide {
        -webkit-transform: translate3d(0, 0, 0); }

      .slider {
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;

        &__controls {
          position: absolute;
          z-index: 1;

          bottom: 45px;
          right: percentage(589 / $base-desktop);

          @include rmin(2200) {
            right: 40%; }

          @include xl {
            right: percentage(458 / $base-laptop);
            bottom: 30px; }

          @include lg {
            right: $gap-container + px;
            bottom: 28px; } }

        &__pagination {
          position: absolute;
          z-index: 1;

          bottom: 40px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 100%;
          max-width: $base-container + px;

          @include xl {
            max-width: $base-container-xl + px;
            bottom: 35px; }

          @include lg {
            max-width: none;
            width: auto;
            transform: none;
            left: $gap-container + px; } } } } }

  &--section {
    &__wrap {
      @include lgmin {
        margin-right: -20vw; }

      .swiper-slide {
        width: 100%;
        max-width: $base-container + px;
        transform-origin: bottom left;
        @include tr(transform .4s $easeInSine);

        @include xl {
          max-width: $base-container-xl + px; }

        &:not(.swiper-slide-active) {
          transform: scale(.9); } }

      .slider {
        &__bottom {
          padding-top: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: $base-container + px;

          @include xl {
            max-width: $base-container-xl + px; } } } } } }
