.features {
  @extend %row;

  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -89px;

  @include lg {
    margin-bottom: -30px; }

  &__item {
    width: percentage(1 / 3);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 89px;

    @include lg {
      margin-bottom: 30px; }

    @include sm {
      width: 50%; }

    @include xs {
      width: 100%; }

    .feature {
      max-width: 426px; }

    &:nth-child(3n+2) {
      @include lgmin {
        padding-left: calc(#{percentage(47 / $base-container)} + 15px); } }

    &:nth-child(3n + 3) {
      @include lgmin {
        display: flex;
        flex-direction: column;
        align-items: flex-end; } } } }

.feature {
  &__title {
    @extend %text-upper-lg;
    margin-bottom: 22px;

    @include xs {
      margin-bottom: 15px; } }

  &__text {
    @extend %text;

    p {
      @include notlast {
        margin-bottom: 20px; } } } }

