.subnav {
  @include sm {
    padding-top: 99px;
    padding-bottom: $side-controls-height-xs + 20px; }

  &__title {
    @extend .subnav-list__link;

    @include smmin {
      position: absolute !important;
      top: 20px;
      right: 30px;
      font-size: 0 !important;
      @include icon-close(30px, 2px, currentColor);
      @include tr(opacity .3s);

      @include hover {
        opacity: $opacity-hover;
        @include after {
          transform: rotate(-45deg); } } }

    @include sm {
      display: inline-flex !important;
      width: auto !important;
      align-items: center;
      padding-bottom: 0 !important;
      margin-bottom: 20px !important;
      font-size: 20px !important;

      &:not(.just-to-make-prioritet-higher) {
        padding-left: 0 !important; } }

    .icon {
      &--arr {
        fill: none;
        stroke: currentColor;
        margin-right: 1em; } } }

  &__list {
    &:nth-child(2) {
      @include smmin {
        margin-bottom: 25px;
        margin-top: 99px; }

      @include xl {
        margin-top: 20px; }

      @include md {
        margin-top: 99px; }

      @include sm {
        margin-top: 40px; } } }


  &__footer {
    background-color: $white;
    color: $c-text;

    padding-top: 65px;
    padding-bottom: 65px;

    @include xl {
      padding-top: 40px;
      padding-bottom: 40px; }

    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; }

    @include sm {
      background-color: $black-light;
      color: $white;
      padding-top: 0;
      padding-bottom: 0;
      border-top: 1px solid rgba($white, .17); } }

  &__footer-in {
    @extend %row; }

  &__footer-title {
    width: percentage(485 / $base-container);

    @include sm {
      width: 100%; }

    .subnav-list__link {
      @include smmin {
        pointer-events: none; } } }

  &__footer-right {
    width: percentage(($base-container - 485) / $base-container);
    @include sm {
      display: none; }
    p {
      @extend %text;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 35px;

      @include xl {
        margin-bottom: 20px; } } } }

.subnav-list {
  $this: &;

  @extend %row;

  margin-left: -15px;
  margin-right: -15px;

  &__item {
    width: percentage(485 / $base-container);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 65px;

    @include xl {
      margin-bottom: 40px; }

    @include lg {
      margin-bottom: 20px; }

    @include sm {
      width: 100%;
      margin-bottom: 0;
      border-bottom: 1px solid rgba($white, .17); }

    #{$this}__link,
    p {
      max-width: 390px;
      @include sm {
        max-width: none; } }

    p, img {
      @include sm {
        display: none; }

      &:not(#{$this}__title) {
        @extend %text;
        text-transform: none;
        font-size: 12px;
        font-weight: 400;

        @include notlast {
          margin-bottom: 25px; } } }

    &:last-child {
      @include sm {
        border-bottom: 0; } }

    &:first-child {
      @include sm {
        border-top: 1px solid rgba($white, .17); } } }

  &__link {
    @extend .title;
    @extend .nav__link;

    font-size: 21px;
    display: inline-block;
    margin-bottom: 15px;
    padding-bottom: 4px;

    @include sm {
      padding-left: 50px !important;
      padding-right: 0 !important;
      margin-bottom: 0;

      font-size: 16px;

      font-family: 'lato', sans-serif !important; }



    &::after {
      border-width: 4px; } }

  &__title {
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 15px;
    color: rgba($white, .4);
    margin-bottom: 19px; }

  &__sublist {
    @extend %text;

    text-transform: none;
    font-size: 14px; }

  &__sublist-item {
    @include notlast {
      margin-bottom: 7px;
      @include sm {
        margin-bottom: 0; } } }

  &__sublist-link {
    @extend .nav__link;

    @include sm {
      padding-left: 50px !important; } }

  &--sm {
    #{$this}__item {
      width: percentage(370 / $base-container);
      @include sm {
        width: 100%; } } } }


