.page-block {
  $this: &;

  padding-top: 54px;
  padding-bottom: 54px;

  h2 {
    &:first-child {
      margin-top: 0; } }

  @include sm {
    padding-top: 40px;
    padding-bottom: 40px; }

  &:last-child {
    &:not(:nth-child(2)) {
      padding-bottom: 0; } }

  &__inner {
    @extend .article__inner;

    p {
      &:not(#{$this}__title):not(.order-block__title) {
        @extend %text; }
      &:not(:last-child) {
        margin-bottom: 20px; } }

    &--row {
      @extend %row; } }

  &__title {
    @extend .article__inner;
    @extend %text-upper-lg;
    margin-bottom: 31px;

    @include sm {
      margin-bottom: 25px; } }

  &__left {
    width: percentage(419 / $base-container--sm);
    padding-right: 15px;

    @include md {
      width: 50%;
      padding-right: 15px; }

    @include sm {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px; } }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: percentage(($base-container--sm - 419) / $base-container--sm);

    @include md {
      width: 50%;
      padding-left: 15px; }

    @include sm {
      width: 100%;
      padding-left: 0; } }

  &__bottom-text {
    margin-top: 100px;
    @include sm {
      margin-top: 40px; } }

  &--lightest {
    background-color: $lightest; }

  &--black {
    background-color: $black;
    color: $white; } }
