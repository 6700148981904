.dropdown {
  $this: &;

  position: relative;

  &[data-state="open"] {
    #{$this}__panel {
      @include visible; } }

  &__opener {
    @extend %text-upper-sm;

    border-bottom: 2px solid currentColor;
    display: flex;
    text-align: left;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;

    @include text-overflow;

    @include after {
      @include icon-arrow(8, 8, 2, currentColor, -45, px);
      display: block;
      margin-left: 0.5em; } }

  &__checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    #{$check-inputs} {
      margin-right: 0.5em;
      opacity: 0;
      position: absolute;
      @include hidden;

      &:checked + span {
        color: $c-text;
        background-color: $lightest; } }

    > span {
      display: block;
      padding: 5px 10px;
      width: 100%;
      @include tr(background-color .3s, color .3s);

      @include hover {
        color: $c-text;
        background-color: $lightest; } } }


  &__option {
    // +notlast
 }    //   margin-bottom: 10px


  &__panel {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    width: 320px;
    max-width: calc(100vw - #{$gap-container * 2});
    border-radius: $radius;
    background-color: $black-light;
    box-shadow: 0 0 10px rgba($black, .15);

    @include hidden;
    @include loaded {
      @include tr(opacity .3s, visibility .3s); } }

  &--text {
    #{$this}__panel {
      background-color: $white;
      color: inherit;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.04); } }

  &--full {
    padding-right: 0;

    #{$this}__panel {
      width: 100%; }
    #{$this}__checkbox {
      #{$check-inputs} {
        &:checked + span {
          color: $white;
          background-color: $black-light; } }
      > span {
        @include hover {
          color: $white;
          background-color: $black-light; } } } } }



