.breadcrumbs {
  @extend %row;
  @extend %text-upper-xs;

  &__item {

    display: inline-flex;
    align-items: center;
    opacity: 0.6;
    @include tr(opacity .3s);

    @include notlast {
      @include after {
        display: inline-block;
        vertical-align: middle;

        width: 12px;
        height: 1px;
        background-color: currentColor;

        margin-left: 12px;
        margin-right: 12px;

        position: relative;
 } }        // top: -0.5em

    &--current {
      @extend %text-overflow;
      display: inline-block;
      opacity: 1;
      max-width: 316px; } }

  &__link {
    @extend %text-overflow;
    @include tr(opacity .3s);

    max-width: 300px;
    display: inline-block;
    align-items: center;

    @include hover-focus {
      opacity: $opacity-hover; } } }

