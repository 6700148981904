%overlay {
  @include overlay; }

%coverdiv {
  @include coverdiv; }

%cover {
  @include cover; }

%clr {
  @include clr; }

%hidden {
  @include hidden; }

%visible {
  @include visible; }

%vcenter {
  @include vcenter; }

%text-overflow {
  @include text-overflow; }

%letter-spacing {
  @include letter-spacing; }

%section-padding {
  padding-top: 140px;
  padding-bottom: 140px;

  @include xl {
    padding-top: 100px;
    padding-bottom: 100px; }

  @include lg {
    padding-top: 70px;
    padding-bottom: 70px; } }

%section-padding-sm {
  padding-top: 100px;
  padding-bottom: 100px;
  @include xl {
    padding-top: 70px;
    padding-bottom: 70px; }
  @include lg {
    padding-top: 50px;
    padding-bottom: 50px; } }

%section-padding-xs {
  padding-top: 50px;
  padding-bottom: 75px;
  @include xl {
    padding-top: 70px;
    padding-bottom: 70px; }
  @include lg {
    padding-top: 50px;
    padding-bottom: 70px; } }

%cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover; }

%hard-hidden {
  display: none; }

%row {
  display: flex;
  flex-wrap: wrap; }

%coverimage {
  width: 100%;
  height: 100%;
  object-fit: cover; }

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

%text {
  // line-height: 2.13
  line-height: 1.4; }

%text-upper {
  font-weight: 700;
  text-transform: uppercase;
  @include letter-spacing; }

%text-upper-lg-l {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.04em; }

%text-upper-lg {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.04em;

  @include xl {
    font-size: 16px; }

  @include xs {
    font-size: 15px; } }

%text-upper-md {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  @include letter-spacing;

  @include sm {
    font-size: 15px; } }

%text-upper-sm {
  font-size: 13px;
  text-transform: uppercase;
  @include letter-spacing;

  @include xl {
    font-size: 12px; } }

%text-upper-xs {
  font-size: 12px;
  text-transform: uppercase;
  @include letter-spacing; }

