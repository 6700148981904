.popup {
  $this: &;

  @extend %overlay;

  z-index: $z-index-modal;
  background-color: $black-transp;
  overflow: auto;
  padding: $gap-container + px;

  @include vertical;
  @include hidden;
  @include loaded {
    @include tr(opacity .3s, visibility .3s); }

  &__inner {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    background-color: $white;
    width: 100%;
    max-width: 750px;
    position: relative;

    padding: 75px 43px;

    @include sm {
      padding: 75px 20px; } }

  &__close {
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 15px; }

  &__radios {
    max-width: 275px;
    margin-left: auto;
    margin-right: auto; }

  &__block {
    margin-bottom: 45px; }

  &__title,
  &__subttl {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }

  &__form {}

  &--sm {
    #{$this}__inner {
      max-width: 462px;
      padding: 75px 43px;

      @include sm {
        padding: 75px 20px; } } }

  &--active {
    @include visible; } }
