.nav {
  $this: &;

  &:not(#{$this}--lg) {
    @extend %text-upper-sm; }

  &__subnav {
    position: absolute;
    z-index: $z-index-controls;
    left: 0;
    right: 0;
    top: $header-top-height;

    background-color: $black-light;
    color: $white;

    @include smmin {
      max-height: 0;
      overflow: hidden;
      @include loaded {
        @include tr(max-height .4s $easeInSine); } }

    @include sm {
      top: $header-height-sm;
      position: fixed;
      overflow: auto;

      @include hidden;
      @include loaded {
        @include tr(opacity .4s, visibility .4s); } }

    &--open,
    &[data-state="open"] {

      @include smmin {
        overflow: auto; }

      @include sm {
        @include visible; } }

    @include sm {
      top: 0;
      bottom: 0; } }

  &__list {
    @extend %row;
    align-items: center;
    height: 100%;
    @include sm {
      height: auto; } }

  &__sublist {
    @extend #{$this}__list;
    font-size: .9em; }

  &__item {
    @include notlast {
      margin-right: 80px;

      @include xl {
        margin-right: 30px; }

      @include lg {
        margin-right: 10px; }

      @include md {
        margin-right: 5px; } }

    .header__nav & {
      @include smmin {
        font-size: 9px; }

      @include mdmin {
        font-size: 10px; }

      @include lgmin {
        font-size: 12px; } }

    &--has-submenu {
      height: 100%;
      display: flex;
      align-items: center;

      @include sm {
        position: relative; }

      > #{$this}__link {
        position: relative;

        @include before {
          @include arr(14px, 9px, currentColor, t);
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translate(-50%, 0);
          @include hidden;
          @include loaded {
            @include tr(opacity .3s, visibility .3s); }

          @include sm {
            display: none; } } }

      @include after {
        @include sm {
          @include icon-arrow(10, 10, 2, currentColor, 225, px);

          pointer-events: none;

          position: absolute;
          right: $gap-container + px;
          top: 50%;
          transform: translate(0, -50%) rotate(225deg); } }

      &[data-state="active"] {
        > #{$this}__link {
          @include before {
            @include visible; } } } }

    &--current {
      > #{$this}__link {
        @include after {
          transform: scaleX(1); } } }

    &--mob {
      @include smmin {
        display: none; } } }

  &__link {
    position: relative;
    display: inline-block;
    @include after {
      border-bottom: 2px solid currentColor;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: scaleX(0);

      @include tr(transform .3s $easeInCirc); }

    @include hover-focus {
      @include after {
        transform: scaleX(1); } } }

  &__footer {
    @extend %row;
    justify-content: space-between;
    align-items: flex-end;

    padding: 0 $gap-container + px; }

  &__toggle-btn {
    display: flex;
    align-items: center;
    position: relative;

    text-transform: none;
    font-size: 1.75em;
    font-weight: 700;

    margin-bottom: 25px;
    margin-top: -50px;

    @include lgmin {
      pointer-events: none; }

    @include lg {
      height: 54px;

      border-bottom: 1px solid rgba(0,0,0,.1);
      border-top: 1px solid rgba(0,0,0,.1);

      padding-left: 50px;
      margin-bottom: 0;
      margin-top: 0; }

    &::before {
      @include icon-arrow(10, 10, 2, currentColor, -45, px);

      transform: translate(0, -50%) rotate(-45deg);
      margin-top: -0.25em; }

    &::after {
      width: 18px;
      height: 18px;

      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTM4IDEyLjgzbC0yLjgzLTIuODMtMTEuMTcgMTEuMTctMTEuMTctMTEuMTctMi44MyAyLjgzIDExLjE3IDExLjE3LTExLjE3IDExLjE3IDIuODMgMi44MyAxMS4xNy0xMS4xNyAxMS4xNyAxMS4xNyAyLjgzLTIuODMtMTEuMTctMTEuMTd6Ii8+PHBhdGggZD0iTTAgMGg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+);
      background-repeat: no-repeat;
      background-size: 18px 18px;

      transform: translate(0, -50%);
      margin-top: -0.1em;

      display: none; }

    &::before,
    &::after {
      content: '';

      position: absolute;
      left: $gap-container + px;
      top: 50%;

      @include lgmin {
        display: none; } } }

  &--header {
    @include sm {
      display: flex;
      flex-direction: column;

      margin-left: -$gap-container + px;
      margin-right: -$gap-container + px;

      font-size: 20px !important;
      letter-spacing: 0.04em;

      #{$this}__list {
        display: block;
        flex-grow: 1;
        margin-bottom: 30px; }

      #{$this}__item {
        height: auto;
        margin-right: 0 !important;

        border-bottom: 1px solid rgba($white, .17);

        &:first-child {
          border-top: 1px solid rgba($white, .17); } }

      #{$this}__link {
        @include f-title;
        padding-top: 15px;
        padding-bottom: 15px;
        display: block;
        width: 100%;

        padding-left: $gap-container + px;
        padding-right: $gap-container + px;

        @include after {
          display: none; } } }

    @include xs {
      #{$this}__list {
        order: 1; }

      #{$this}__footer {
        margin-bottom: 30px; } } }

  &--full-lg {
    padding: 18px $gap-container + px;
    background-color: $lightest;

    font-weight: 700;
    font-size: 14px;

    #{$this}__list {
      margin-left: auto;
      margin-right: auto;
      max-width: $base-container;
      justify-content: center;
      @include xl {
        max-width: $base-container-xl; } } }

  &--content {
    font-size: 13px;
    #{$this}__list {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start; }

    #{$this}__item {
      @include notlast {
        margin-right: 0 !important;
        margin-bottom: 10px; } } }

  &--vert {
    #{$this}__list {
      display: block; }

    #{$this}__sublist {
      margin-left: 1em;
      margin-top: 15px; }


    #{$this}__item {
      @include notlast {
        margin-right: 0;
        margin-bottom: 22px;

        @include sm {
          margin-bottom: 14px; } }

      &--lg {
        font-size: 14px;
        font-weight: 700;
        @include notlast {
          margin-bottom: 39px; } } } }

  &--between {
    #{$this}__item {
      margin-bottom: 26px;

      @include md {
        margin-left: 19px;
        margin-right: 19px; }

      @include notlast {
        margin-right: 0;
        @include md {
          margin-right: 19px; } } }

    #{$this}__list {
      justify-content: space-between;
      margin-bottom: -26px;
      @include md {
        justify-content: center; } } }

  &--sm {
    text-transform: none !important;
    font-weight: 400 !important;
    font-size: 14px !important; }

  &--md {
    @extend %letter-spacing;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700; }

  &--lg {
    @extend %text-upper-lg;

    #{$this}__link {
      display: inline-flex;
      align-items: center;
      overflow: hidden;

      @include before {
        @include icon-arrow(10, 10, 2, currentColor, 225, px);

        display: block;
        margin-right: 15px;
        margin-left: -20px;

        @include tr(margin .4s); }

      @include hover-focus {
        @include before {
          margin-left: 0; } } } } }
