.service-ul {
  background-color: $white;
  border-radius: $radius;
  padding: 40px;

  &__item {
    @include notlast {
      margin-bottom: 30px; } } }

.service-checkbox {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .04em;

  display: flex;
  align-items: center;

  cursor: pointer;

  #{$check-inputs} {
    width: 15px;
    height: 15px;
    border-radius: 50%;

    border: 1px solid $white;
    box-shadow: 0 0 0 1px $black;

    flex-shrink: 0;

    appearance: none;

    &:checked {
      background-color: $black; } }

  &__label {
    display: flex;
    align-items: center;
    padding-left: 24px;
    flex-grow: 1;

    span {
      &:last-child {
        font-weight: 700;
        margin-left: auto;
        text-align: right; } } } }


.service-dl {
  @extend %row;
  align-items: flex-end;

  background-color: $white;
  padding: 40px 40px 10px;


  font-size: 14px;
  letter-spacing: 0.04em;

  .page-block--lightest & {
    @include sm {
      padding: 0;
      background-color: $lightest; } }

  &__title,
  &__text {
    margin-bottom: 30px;

    @include sm {
      margin-bottom: 21px; } }

  &__title {
    width: calc(100% - 100px);
    font-weight: 400;
    text-transform: uppercase; }

  &__text {
    width: 100px;
    font-weight: 700;
    text-align: right; } }

.service-list {
  @include counter('service', '', decimal-leading-zero);
  @extend %text;

  &__item {
    padding-left: 3.8em;
    position: relative;
    @include sm {
      padding-left: 2.5em; }
    @include notlast {
      margin-bottom: 12px; }

    &::before {
      position: absolute;
      left: 0;
      top: 0; } } }
