.services {
  @extend %row;

  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;

  @include xl {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -20px; }

  &__item {
    padding-left: 15px;
    padding-right: 15px;
    width: percentage((609 + 15) / $base-container);
    margin-bottom: 30px;

    @include xl {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      width: percentage((436 + 10) / $base-container-xl); }

    @include md {
      width: 100%;
      margin-bottom: $gap-container + px; }

    &--lg {
      width: percentage(($base-container - 609 - 15) / $base-container);

      @include xl {
        width: percentage(($base-container-xl - 436 - 10) / $base-container-xl); }

      @include md {
        width: 100%; } }

    .service-link {
      height: 100%; } } }

.service-link {
  $this: &;

  display: block;

  position: relative;

  background-color: $dark;
  color: $white;
  overflow: hidden;

  @include hover-focus {
    @include before {
      @include visible; }

    #{$this}__bg {
      transform: scale(1.1); } }

  @include before {
    @include coverdiv;
    z-index: 1;
    background-color: $black-transp;
    @include tr(opacity .4s, visibility .4s);
    @include hidden; }


  &__bg {
    @extend %coverdiv;
    @extend %coverimage;
    z-index: 0;

    @include tr(transform .4s $easeOutSine); }

  &__inner {
    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 2;

    padding: 35px;
    min-height: 470px;

    @include xl {
      min-height: 336px;
      padding: 25px; }

    @include lg {
      min-height: vw(336, $base-laptop); }

    @include md {
      min-height: vw(216, $base-mobile);
      padding: 30px $gap-container + px; } }

  &__title {
    @extend .title;
    @extend .title--h2;

    margin-bottom: 20px;

    @include xl {
      font-size: 30px;
      margin-bottom: 15px; }

    @include md {
      font-size: 25px; } }

  &__subttl {
    // @extend %text-upper-lg
    @extend %text;

    max-width: 509px;
    display: block;
    margin-bottom: 22px; }

  &__btn {
    margin-top: auto; } }

.services-full {
  @extend %row;

  max-width: $base-container + ($gap-container * 2) + px;
  margin-left: auto;
  margin-right: auto;

  @include xl {
    max-width: $base-container-xl + ($gap-container * 2) + px; }

  &__wrap {
    overflow: hidden; }

  &__item {
    position: relative;
    width: 50%;

    padding: 120px $gap-container + px;

    @include xl {
      padding: 80px $gap-container + px; }

    @include lg {
      padding: 50px $gap-container + px; }

    @include sm {
      width: 100%; }

    .service-full-item {
      margin-left: auto;
      margin-right: auto;
      max-width: 550px;

      position: relative;

      @include xlmin {
        left: percentage((154 - 107) / $base-container); } }

    &:nth-child(2),
    &:nth-child(3) {
      background-color: $black-light;
      color: $white;

      @include before {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50vw;
        background-color: $black-light; }

      .service-full-item {
        left: auto;
        @include xlmin {
          right: percentage((154 - 107) / $base-container); } } }

    &:nth-child(2) {
      @include before {
        left: 100%; } }
    &:nth-child(3) {
      @include before {
        right: 100%; } }

    &:nth-child(odd) {
      @include sm {
        background-color: $white;
        color: inherit;
        .btn--white {
          color: inherit;
          border-color: currentColor; } } }
    &:nth-child(even) {
      @include sm {
        background-color: $black-light;
        color: $white; } } } }

.service-full-item {
  &__img {
    font-size: 0;
    margin-bottom: 45px;
    @include md {
      margin-bottom: 30px; }
    img {
      width: 100%; } }

  &__title {
    margin-bottom: 23px;
    @include md {
      margin-bottom: 14px; } }

  &__text {
    @include notlast {
      margin-bottom: 45px;
      @include sm {
        margin-bottom: 30px; } } } }

