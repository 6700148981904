.header {
  $this: &;

  &__city,
  &__dropdown,
  &__contacts {
    @include lg {
      display: none !important; } }

  &__logo,
  &__burger {
    @include md {
      position: relative;
      z-index: $z-index-controls + 1; } }

  &__contacts,
  &__lang {
    @include sm {
      font-size: 14px;
      font-weight: 400;
      @include reg; } }

  &__nav {
    height: 100%;
    @include sm {
      position: fixed;
      z-index: $z-index-controls;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      overflow: auto;
      padding: 154px $gap-container + px 35px;

      background-color: $black-light;
      color: $white;

      .has-open-subnav & {
        overflow: hidden;
 }        // padding: 0

      @include xs {
        padding-bottom: $side-controls-size + 15px; }

      @include hidden;
      @include loaded {
        @include tr(opacity .4s, visibility .4s); }

      &[data-state="open"] {
        @include visible; } } }

  &__inner {
    display: flex;
    align-items: center; }

  &__top {
    background-color: $white;
    position: relative;
    z-index: 2;

    @include sm {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.04);
      .has-menu-open & {
        color: $white;
        @include before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 11;
          background-color: $black-light; } } }

    #{$this}__inner {
      height: $header-top-height;

      @include sm {
        height: $header-top-height-sm; } } }

  &__bottom {
    background-color: $black-light;
    color: $white;

    @include tr(transform .4s $easeInSine);

    @include sm {
      display: none; }

    #{$this}__inner {
      height: $header-bottom-height;

      @include xl {
        height: $header-bottom-height-xl; } } }

  &__lang {
    @include sm {
      display: none !important; }

    &.toggle-list {
      margin-left: auto; }

    &--mob {
      @include smmin {
        display: none !important; }
      @include sm {
        display: flex !important; } } }

  &__logo {
    margin-right: 56px;

    @include xl {
      margin-right: 47px; }

    &--top {
      @include smmin {
        display: none; } } }

  &__city {
    &.toggle-list {
      margin-right: 153px;

      @include xl {
        margin-right: 40px; } } }

  &__dropdown {
    margin-right: 58px;
    max-width: 150px; }

  &__btn {
    margin-left: auto; }

  &__contacts-wrap {
    display: flex;
    align-items: center; }

  &__contacts {
    max-width: 60%;
    @include xl {
      max-width: 65%; }
    @include sm {
      max-width: none;
      flex-grow: 1; }

    &--mob {
      @include smmin {
        display: none !important; }
      @include sm {
        display: block !important; } } }


  &__burger {
    margin-left: auto;
    @include smmin {
      display: none !important; } }

  &--shrinked {
    height: $header-top-height;

    @include xl {
      height: $header-top-height-xl; }

    @include sm {
      height: $header-top-height-sm; }

    #{$this}__bottom {
      transform: translate(0, -105%); } } }

.burger {
  display: block;
  width: 38px;
  height: 16px;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: currentColor;
    left: 0;
    position: absolute;
    @include tr(transform .4s); }

  &::before {
    top: 0; }

  &::after {
    bottom: 0; }

  &[data-state="active"] {
    &::before {
      transform: rotate(45deg) translate(5px, 5px); }

    &::after {
      transform: rotate(-45deg) translate(5px, -5px); } } }
