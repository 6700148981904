.hero {
  $this: &;

  @extend %section-padding;

  @include md {
    padding-top: 60px;
    padding-bottom: 60px; }

  @include sm {
    padding-top: 30px;
    padding-bottom: 30px; }

  &__top {
    margin-bottom: 45px;
    @include xl {
      margin-bottom: 27px; } }

  &__top-in {
    // @extend %row
    @include lgmin {
      display: flex; } }

  &__nav {
    width: percentage(275 / $base-container);

    @include xl {
      width: percentage(197 / $base-container-xl);
      padding-right: 22px; }

    // +lg
    //   display: none !important

    &.nav--content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include lg {
        position: relative;
        width: calc(100% + #{$gap-container * 2 + px});

        margin-left: -$gap-container + px;
        margin-right: -$gap-container + px;
        padding-right: 0;

        margin-bottom: 20px;

        .nav {
          &__list {
            display: block;

            position: absolute;
            z-index: 5;
            top: 100%;
            left: 0;
            right: 0;

            height: auto;

            padding-right: $gap-container + px;
            padding-left: $gap-container + px;
            background-color: #f2f2f2;

            @include hidden; }

          &__item {
            margin-bottom: 0;
            @include notlast {
              border-bottom: 1px solid rgba(0,0,0,.1); } }

          &__link {
            display: block;
            padding-top: 15px;
            padding-bottom: 15px; } }

        &[data-state="open"] {
          .nav__toggle-btn {
            &::before {
              display: none; }
            &::after {
              display: block; } }

          .nav__list {
            @include visible; } } } } }

  &__title {
    width: percentage(773 / $base-container);
    padding-left: 22px;
    padding-right: 22px;

    margin-top: -.15em;

    @include xl {
      width: percentage(569 / $base-container-xl);
      padding-left: 0;
      margin-top: 0; }

    @include lg {
      width: 50%;
      padding-right: 10px;
      padding-left: 0; }

    @include md {
      width: 100%;
      padding-right: 0;
      margin-bottom: 14px; } }

  &__text {
    @extend %text;

    width: percentage(($base-container - 275 - 773) / $base-container);
    max-width: 455px;
    margin-top: auto;
    margin-bottom: 0.15em;

    @include xl {
      width: percentage(($base-container-xl - 197 - 569) / $base-container-xl); }

    @include lg {
      width: 50%;
      padding-left: 10px; }

    @include md {
      width: 100%;
      padding-left: 0; } }

  &__img-link {
    position: absolute;
    z-index: 1;
    bottom: 45px;
    right: $gap-container + px;

    @include xl {
      bottom: 30px; }

    @include lg {
      display: none; } }

  &__img-wrap {
    position: relative;
    color: $white;

    &:not(#{$this}__img--content) {
      font-size: 0; } }

  &__img {
    position: relative;
    padding-top: percentage(589 / $base-container);
    background-color: $light;

    @include lg {
      padding-top: 50%; }

    @include md {
      padding-top: 60%; }

    @include xxs {
      padding-top: percentage(348 / 382); }

    img {
      @extend %coverdiv;
      @extend %coverimage; } }

  &--p-sm {
    @extend %section-padding-sm; } }

.hero-slide {
  $this: &;

  position: relative;
  color: $white;
  background-color: $dark;

  &.swiper-slide-active {
    #{$this}__bg {
      &.lazy--loaded {
        animation: fade-in 1s; } } }

  &__bg {
    &:not(.lazy--loaded) {
      @extend %vcenter; }

    &.lazy--loaded {
      @extend %coverdiv;
      @extend %coverimage;

      z-index: 0; } }

  &__btn {
    @extend .btn;
    @extend .btn--white;
    @extend .btn--filled;

    @include xs {
      border: 0;
      padding: 0;
      min-height: 0;
      text-align: left;
      justify-content: flex-start;

      @include before {
        display: none; }

      @include after {
        @include icon-arrow(9, 9, 2, currentColor, 225, px);
        display: block;
        margin-left: 1em; } } }

  &__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;

    position: relative;
    z-index: 1;

    height: vw(653);

    @include xl {
      height: vw(435, $base-laptop); }

    @include lg {
      height: 45vw; }

    @include sm {
      height: vw(348, $base-mobile); } }

  &__title {
    @extend .title;

    font-size: 65px;
    letter-spacing: 0.04em;

    margin-bottom: 30px;
    max-width: 750px;

    @include xl {
      margin-bottom: 10px;
      font-size: 55px; }

    @include lg {
      font-size: 45px; }

    @include md {
      font-size: 38px; }

    @include xs {
      margin-bottom: 20px;
      font-size: 32px; } }

  &__text {
    @extend %text;

    margin-bottom: 42px;
    max-width: 658px;

    font-size: 17px;
    line-height: 1.7;

    @include xs {
      display: none; } } }
