.article {
  $this: &;

  &__top {
    position: relative;
    margin-bottom: 50px;

    @include xl {
      margin-bottom: 30px; }

    h1, h2 {
      @extend #{$this}__inner; } }

  &__inner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $base-container--sm + px; }

  &__block {
    margin-bottom: 55px;
    @include sm {
      margin-bottom: 21px; } }

  &__date {
    @extend #{$this}__inner;

    display: block;
    font-size: 15px;
    color: $dark;

    margin-bottom: 19px;

    @include lgmin {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 16px;
      margin-bottom: 0; } }

  // table
  //   @extend .table

  //// td,
  //// th
  ////   width: auto !important

  //// th
  ////   border-color: $lightest !important

  //// td
  ////   &:nth-child(1)
  ////     border-right-color: $light !important

  //// tr
  ////   +hover
  ////     td
  ////       border-color: $lightest !important

  h1, h2, h3, h4, h5, h6 {
    @extend .title;
    @include reg;
    font-weight: 700;
    text-transform: none; }

  h1 {
    @extend .title--h2;
    margin-bottom: 32px; }

  h2, h3 {
    margin-bottom: 25px;
    margin-top: 45px;

    @include sm {
      margin-bottom: 18px;
      margin-top: 40px; } }

  h2 {
    @extend .title--h4;
    line-height: 1.5; }

  h3 {
    @extend .title--h5; }

  p, img, picture, table, .table__wrap,
  ul:not(.service-list),
  ul:not(.btns-list),
  ul:not(.btns),
  ol:not(.service-list),
  ol:not(.btns-list),
  ol:not(.btns) {
    @extend %text;

    @include notlast {
      margin-bottom: 25px; } }

  ol {
    list-style-type: decimal;
    margin-left: 1.2em; }

  li {
    @include notlast {
      margin-bottom: 10px; } }

  picture,
  img {
    display: block;
    font-size: 0;
    margin-top: 30px;

    @include notlast {
      margin-bottom: 75px;
      @include xl {
        margin-bottom: 50px; }
      @include sm {
        margin-bottom: 42px; } } }

  picture {
    > img {
      margin-top: 0;
      @include notlast {
        margin-bottom: 0 !important; } } }

  ul {
    &:not(.service-list) {
      @extend .list; }

    &.btns {
      li {
        padding-left: 10px;
        padding-right: 10px;

        @include before {
          display: none; } } } }

  a {
    &:not(.btn) {
      @extend .map-place-card__link; } } }
