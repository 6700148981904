.link-blocks {
  @extend %row;

  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;

  &__item {
    padding-left: 15px;
    padding-right: 15px;
    width: percentage(1 / 3);
    margin-bottom: 30px;

    @include lg {
      width: 50%; }

    @include sm {
      width: 100%; }

    .link-block {
      height: 100%; } } }

.link-block {
  $this: &;

  background-color: $dark;
  color: $white;
  position: relative;
  display: block;
  overflow: hidden;

  @include before {
    @extend %coverdiv;
    z-index: 1;

    background-color: $black-transp;
    @include tr(opacity .4s, visibility .4s);
    @include hidden; }

  &[href] {
    @include hover-focus {
      @include before {
        @include visible; } }

    @include hover-focus {
      @include before {
        @include visible; }

      #{$this}__bg {
        transform: scale(1.1); } } }

  &__bg {
    @extend %coverdiv;
    @extend %coverimage;
    z-index: 0;
    @include tr(transform .4s $easeOutSine); }

  &__inner {
    display: flex;
    flex-direction: column;

    padding: 60px 35px 35px;
    height: 100%;

    position: relative;
    z-index: 2;

    min-height: vw(589);

    @include xl {
      padding: 30px; }

    @include md {
      padding: 25px; }

    @include sm {
      min-height: vw(382, $base-mobile); } }

  &__title {
    margin-bottom: 20px;
    display: block; }

  &__btn {
    margin-top: auto; } }
