.posts {
  $this: &;

  &--simple {
    @extend %row;

    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -40px;

    @include xl {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: -30px; }

    #{$this}__item {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 40px;

      width: 50%;

      @include xl {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px; }

      @include sm {
        width: 100%; }

      &--full {
        width: 100%; } } }

  &--section {
    @extend %clr;

    margin-left: -15px;
    margin-right: -15px;

    @include xl {
      margin-left: -10px;
      margin-right: -10px; }

    @include md {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -35px; }

    #{$this}__item {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;

      width: percentage(($base-container - 825 - 15) / $base-container);

      float: left;

      .post {
        @include md {
          height: 100%; } }

      @include xl {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        width: percentage(($base-container-xl - 590 - 10) / $base-container-xl); }

      @include md {
        float: none;
        width: percentage(1 / 3) !important;
        margin-bottom: 35px !important; }

      @include sm {
        width: 100% !important; }

      &:nth-child(1) {
        width: percentage((825 + 15) / $base-container);
        margin-bottom: 0;

        @include xl {
          width: percentage((590 + 10) / $base-container-xl); } } } } }

.post {
  $this: &;

  display: flex;

  @include hover-focus {
    #{$this}__img {
      @include before {
        @extend %visible; }

      img {
        transform: scale(1.1); } } }

  @include md {
    flex-direction: column; }

  &__img {
    display: block;
    width: percentage(353 / 762);
    font-size: 0;

    position: relative;
    padding-top: 36.6%;

    background-color: $dark;

    overflow: hidden;

    @include xl {
      width: percentage(253 / 550); }

    @include md {
      padding-top: percentage(273 / 382);
      width: 100%; }

    @include before {
      @extend %coverdiv;
      @extend %hidden;

      background-color: $black-transp;
      z-index: 1;

      @include tr(opacity .4s, visibility .4s); }

    img {
      @extend %coverdiv;
      @extend %coverimage;
      z-index: 0;

      @include tr(transform .4s $easeOutSine); } }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    width: percentage((762 - 353) / 762);

    @include xl {
      justify-content: space-between;
      padding-left: 20px;
      width: percentage((550 - 253) / 550); }

    @include md {
      flex-grow: 1;
      justify-content: flex-start;
      width: 100%;
      padding: 17px 0 0 0 !important; } }

  &__date {
    display: block;
    font-size: 16px;
    color: $dark;
    margin-bottom: 20px;

    @include xl {
      margin-bottom: 0;
      font-size: 15px;
      padding-top: 10px; }

    @include md {
      padding-top: 0;
      margin-bottom: 14px; }

    @include sm {
      font-size: 15px; } }

  &__title {
    @extend .title;

    font-size: 29px;
    margin-bottom: 20px;

    @include smmin {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 2.95em; }

    @include xl {
      font-size: 19px;
      margin-bottom: 0; }

    @include md {
      font-size: 20px;
      margin-bottom: 20px; } }

  &__link {
    @include xl {
      max-width: 237px;
      margin-bottom: 15px; }

    @include md {
      margin-bottom: 0;
      margin-top: auto; } }

  &--simple {
    display: block;
    #{$this}__img,
    #{$this}__content {
      width: 100%; }

    #{$this}__content {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 28px 0 0;

      @include lg {
        display: block; } }

    #{$this}__title,
    #{$this}__descr {
      width: calc(100% - 165px);
      margin-left: auto;
      flex-shrink: 0;

      @include xl {
        width: calc(100% - 120px); }

      @include lg {
        width: 100%;
        margin-left: 0; } }

    #{$this}__date {
      width: 165px;
      flex-shrink: 0;

      @include xl {
        width: 120px; }

      @include lg {
        width: 100%; } }

    #{$this}__title {
      font-size: 41px;
      margin-bottom: 10px;
      @include xl {
        font-size: 35px; }
      @include lg {
        font-size: 28px; }
      @include sm {
        font-size: 20px; } }

    #{$this}__descr {
      font-size: 15px; }

    #{$this}__img {
      padding-top: percentage(421 / 790);

      @include sm {
        padding-top: percentage(273 / 382); } } }

  &--xl {
    position: relative;
    color: $white;

    @include sm {
      color: inherit; }

    #{$this}__content {
      display: block;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      padding: 50px 70px;

      @include xl {
        padding: 40px; }

      @include md {
        padding: 20px !important; }

      @include sm {
        position: static;
        padding: 17px 0 0!important; } }

    #{$this}__img {
      padding-top: percentage(630 / $base-container);

      @include sm {
        padding-top: percentage(273 / 382); }

      @include before {
        @extend %visible;
        background-color: rgba($black, .2); } }

    #{$this}__title,
    #{$this}__descr {
      width: 100%;
      margin-left: 0; }

    #{$this}__descr {
      @include smmin {
        display: none; } }

    #{$this}__title {
      font-size: 70px;

      @include xl {
        font-size: 50px; }

      @include lg {
        font-size: 40px; }

      @include sm {
        font-size: 20px; } }

    #{$this}__date {
      @include smmin {
        color: inherit;
        font-size: 18px; } } }

  &--lg {
    display: block;

    #{$this}__img,
    #{$this}__content {
      width: 100%; }

    #{$this}__img {
      padding-top: 71.4%;

      @include md {
        padding-top: percentage(273 / 382); } }

    #{$this}__content {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      padding: 27px 0;

      @include xl {
        padding: 20px 0; } }

    #{$this}__date {
      padding-top: 10px;
      width: percentage(140 / (825 + 15));
      @include xl {
        padding-top: 0;
        width: percentage(130 / (590 + 10)); }

      @include md {
        width: 100%; } }

    #{$this}__title {
      width: percentage((825 - 10 - 140) / (825 + 15));
      font-size: 35px;
      margin-bottom: 45px;

      @include xl {
        font-size: 22px;
        margin-bottom: 20px;
        width: percentage((590 - 10 - 140) / (590 + 10)); }

      @include md {
        width: 100%;
        font-size: 20px; } }

    #{$this}__link {
      margin-left: percentage(140 / (825 + 15));
      @include xl {
        margin-left: percentage(130 / (590 + 10)); }

      @include md {
        margin-left: 0; } } } }
