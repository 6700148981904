.contacts-radios {
  text-align: left;
  &__item {
    @include notlast {
      margin-bottom: 30px; } } }

.contacts-radio {
  position: relative;
  display: block;
  padding-left: 60px;
  cursor: pointer;

  #{$check-inputs} {
    position: absolute;
    top: 0;
    left: 0;

    width: 24px;
    height: 24px;

    border: 3px solid $white;
    border-radius: 50%;

    box-shadow: 0 0 0 1px $black;
    appearance: none;

    &:checked {
      background-color: $black; } } }

.contacts-list {
  $this: &;

  @extend %text-upper-sm;

  display: block;
  margin-bottom: -9px;

  &__item {
    display: block;
    margin-bottom: 9px; }

  &__link {
    @include tr(opacity .3s);
    @include hover-focus {
      opacity: .7; } }

  &--hor {
    display: flex;
    @extend %row;

    #{$this}__item {

      &:nth-child(even) {
        text-align: right;
        width: 45%;
        @include sm {
          width: 100%;
          text-align: left; } }

      &:nth-child(odd) {
        width: 55%;
        @include sm {
          width: 100%; } } } }

  &--lg {
    font-size: 15px; }

  &--md {
    font-size: 1rem; } }

.contacts-block {
  $this: &;

  @extend %text-upper-sm;

  &__text {
    margin-bottom: 25px; }

  &__address {
    text-decoration: underline;
    margin-bottom: 5px; }

  &--lg {
    font-size: 1rem;

    #{$this}__text {}

    #{$this}__address {
      text-decoration: none;
      margin-bottom: 10px; }

    #{$this}__schedule {
      > span {
        display: block; } } } }

.map {
  background-color: $dark;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  &__place-card {
    position: absolute;
    z-index: 3;
    top: 10px;
    left: 10px;
    width: 300px;

    @include xs {
      width: 200px; } } }

.map-place-card {
  $this: &;

  @extend %row;

  background-color: $white;
  box-shadow: 0 0 10px rgba($black, .15);
  padding: 10px;
  font-size: 12px;

  &__left {
    width: 70%;
    padding-right: 10px;
    @include xs {
      width: 100%; } }

  &__right {
    width: 30%;
    text-align: center;
    #{$this}__link {
      display: inline-block; } }

  &__title {
    @extend %text-overflow;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px; }

  &__subttl {
    margin-bottom: 10px;
    color: $dark; }

  &__link {
    color: $c-link;
    .icon {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
      font-size: 1.5em; }
    @include hover-focus {
      text-decoration: underline; } }

  &__reviews {
    @extend %row;
    align-items: center;
    margin-bottom: 10px;


    #{$this}__link {
      width: 50%; } }

  &__rating {
    width: 50%; }

  &__title,
  &__right,
  &__subttl,
  &__reviews {
    @include xs {
      display: none; } } }

.address {
  $this: &;

  @extend %row;

  &__title {
    @extend %text-upper-md;

    width: percentage(286 / 520);
    padding-right: 15px;
    display: block;

    @include xl {
      width: 100%;
      margin-bottom: 34px;
      padding-right: 0; }

    @include xs {
      margin-bottom: 24px; } }

  &__content {
    width: percentage((520 - 286) / 520);

    @include xl {
      width: 100%; } }

  &--sm {
    display: block;

    #{$this}__title {
      font-size: 15px;
      margin-bottom: 18px;
      width: auto; }

    #{$this}__content {
      width: auto; } } }

.toggle-contacts {
  &__block {
    display: flex;
    align-items: center;

    &:not([data-state="active"]) {
      display: none; } }

  &__address {
    &:not([data-state="active"]) {
      display: none; } } }
