$base-fz:            15;
$base-fz-xl:         14;
$base-fz-sm:         14;
$base-desktop:       1920;
$base-laptop:        1280;
$base-mobile:        414;

$base-container:     1620;
$base-container-xl:  1160;
$gap-container:      16;

$base-container--sm: 856;
$base-container--md: 1130;

$text-inputs:  "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']", "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']", "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']", "input:not([type])", "textarea";
$check-inputs: "[type='checkbox']", "[type='radio']";

$c-text:       #000;
$c-link:       blue;
$danger:       red;
$black:        #000;
$black-light:  #141414;
$black-transp: rgba($black, .6);
$white:        #fff;
$dark:         #727272;
$light:        #E6E6E6;
$lightest:     #F0F2F5;
$gold:         #ffea08;
$danger:       red;

$z-index-controls: 10;
$z-index-modal:    15;

$header-top-height:       47px;
$header-top-height-xl:    47px;
$header-top-height-sm:    63px;

$header-bottom-height:    83px;
$header-bottom-height-xl: 79px;

$header-height:           $header-top-height + $header-bottom-height;
$header-height-xl:        $header-top-height-xl + $header-bottom-height-xl;
$header-height-sm:        $header-top-height-sm;

$side-controls-size:      50px;
$side-controls-height-xs: 52px;

$offline-panel-height:    35px;

$radius:        2px;
$opacity-hover: .7;
