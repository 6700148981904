html,
body {
  height: 100%; }

html {
  font-size: $base-fz + px;
  line-height: 1.4;
  color: $c-text;
  @include reg;
  @include xl {
    font-size: $base-fz-xl + px; } }

body {
  background-color: $white;
  min-width: 320px; }

@page {
  margin: 16mm; }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.hr {
  margin: 0;
  border: 0;
  height: 0;
  border-top: 4px solid $black;

  width: 100%;
  max-width: $base-container + px;
  margin-left: auto;
  margin-right: auto;

  @include xl {
    max-width: $base-container-xl + px; }

  @include r($base-container-xl + ($gap-container * 2)) {
    max-width: calc(100% - #{$gap-container * 2 + px}); }

  @include sm {
    display: none; }

  &:last-child {
    margin-bottom: 140px;
    @include xl {
      margin-bottom: 100px; }

    @include lg {
      margin-bottom: 70px; } } }

.main {
  &__breadcrumbs {
    padding-top: 50px;

    &--last {
      padding-top: 76px;
      margin-bottom: 50px; } } }

.container {
  width: 100%;

  margin-left: auto;
  margin-right: auto;

  padding-left: $gap-container + px;
  padding-right: $gap-container + px;

  max-width: $base-container + ($gap-container * 2) + px;

  @include xl {
    max-width: $base-container-xl + ($gap-container * 2) + px; }

  &--md {
    max-width: $base-container--md + px; }

  &--pdf {
    max-width: 95%;
    @include xl {
      max-width: 95%; } } }

.logo {
  letter-spacing: .01em;
  white-space: nowrap;

  display: inline-block;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1;
  @include f-title;
  font-weight: 700;

  @include xl {
    font-size: 18px; }

  @include lg {
    font-size: 16px; }

  @include sm {
    font-size: 14px; }

  &__title {
    i {
      font-style: inherit;

      @include xl {
        display: block; } } } }

.close {
  @include icon-close(20px, 2px, currentColor);
  @include tr(opacity .3s);

  @include hover {
    opacity: $opacity-hover;
    @include after {
      transform: rotate(-45deg); } } }

.auth {
  font-size: 0; }

.text-block {
  &__title {
    @extend %text-upper-lg;

    margin-bottom: 20px;

    @include sm {
      margin-bottom: 7px; } }

  &__text {
    @include notlast {
      margin-bottom: 40px;

      @include sm {
        margin-bottom: 34px; } } }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 296px;
    margin-left: auto;
    margin-right: auto;

    .btn {
      margin-left: $gap-container + px;
      margin-right: $gap-container + px;

      @include sm {
        margin-left: $gap-container / 2 + px;
        margin-right: $gap-container / 2 + px; } } } }

.title-block {
  $this: &;

  &__upttl {
    color: rgba($dark, .7);
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 17px;
    letter-spacing: 0.15em;
    margin-left: 0.2em;

    @include xl {
      font-size: 15px;
      margin-bottom: 17px; } }

  &--line {
    position: relative;

    @include before {
      @include mdmin {
        position: absolute;
        z-index: 0;
        bottom: .6em;
        left: 0;
        width: 100%;
        height: 4px;

        background-color: $black; } }

    #{$this}__title {
      @include mdmin {
        position: relative;
        z-index: 1;
        display: inline-block;
        padding-right: 25px;
        background-color: $white; } } } }

.img-link {
  display: block;

  &__img {
    display: block;
    position: relative;
    background-color: $light;

    width: 340px;
    height: 206px;
    img {
      @extend %coverdiv;
      @extend %coverimage; } }

  &__title {
    @extend %text-upper-lg;

    display: flex;
    align-items: center;

    margin-bottom: 17px;

    @include after {
      @include icon-arrow(9, 9, 2, currentColor, 225, px);
      display: block;
      margin-left: 22px; } } }

.faq-list {
  &__item {
    @include notlast {
      margin-bottom: 25px; } } }

.faq-item {
  &__title {
    display: inline-block;
    text-align: left;
    cursor: pointer;
    @include tr(color .3s);

    @include hover-focus {
      color: rgba($c-text, .5); } }

  &__text {
    max-height: 0;
    overflow: hidden;
    @include tr(max-height .4s $easeInSine);

    &::before {
      content: '—';
      display: inline-block;
      margin-right: .5em; } } }

.btns-list {
  margin-bottom: 30px;

  &__title {
    margin-bottom: 15px; }

  &__item {
    @include notlast {
      margin-bottom: 30px; } } }

.btns {
  @extend %row;

  margin-left: -10px;
  margin-right: -10px;

  &__item {
    width: percentage(1 / 2);
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;

    @include xs {
      width: 100%; } } }

.rating {
  $this: &;

  display: flex;
  align-items: center;
  color: #e7711b;

  &__title {
    margin-right: 0.3em; }

  &__stars {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;

    & > #{$this}__input:checked ~ #{$this}__label {
      color: #e7711b; } }

  &__input {
    visibility: hidden;
    opacity: 0;
    position: absolute; }

  &__label {
    color: #dddddd;
    font-size: 0; }

  &__icon {
    font-size: .7rem; }

  &--interactive {
    #{$this}__stars {
      & > #{$this}__label:hover,
      & > #{$this}__label:hover ~ #{$this}__label {
        color: #e7711b; } } } }

.offline-panel {
  background-color: $black;
  color: $danger;
  text-align: center;
  font-size: 0.7em;

  &__inner {
    @extend %flex-center;

    height: $offline-panel-height; } }

