// +font('lato', 'lato-light-webfont', 300)
// +font('lato', 'lato-regular-webfont', 400)
// +font('lato', 'lato-bold-webfont', 700)

// +font('lato', 'lato-italic-webfont', 400, italic)

// +font('ermilov', 'ermilov-webfont', 700)

@mixin reg {
  font-family: 'lato', sans-serif; }

@mixin f-title {
  font-family: 'ermilov', sans-serif; }

