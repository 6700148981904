.title {
  @include f-title;
  display: block;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  &--h1 {
    font-size: 70px;
    @include xl {
      font-size: 56px; }

    @include lg {
      font-size: 48px; }

    @include md {
      font-size: 42px; }

    @include xs {
      font-size: 36px; }

    @include xxs {
      font-size: 28px; } }

  &--h2 {
    font-size: 53px;
    @include xl {
      font-size: 38px; }
    @include xs {
      font-size: 28px; }
    @include xxs {
      font-size: 23px; } }

  &--h3 {
    font-size: 35px;
    @include xl {
      font-size: 28px; }
    @include xs {
      font-size: 23px; } }

  &--h4 {
    @extend %text-upper-lg; }

  &--h5 {
    font-size: 1em; } }

.subttl {
  @extend %text;
  color: rgba($c-text, .43); }

.text {
  @extend %text;

  &--bold {
    line-height: 1.6;
    font-weight: 700; } }

.list {
  $this: &;

  @extend %text;

  li {
    position: relative;
    padding-left: em(27, 15);

    @include sm {
      padding-left: em(22, $base-fz-sm); }

    @include notlast {
      margin-bottom: 10px; }

    > a {
      @extend .map-place-card__link; }

    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: .5em;

      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: currentColor; } }

  &--lg {
    @extend %text-upper;

    li {
      @include notlast {
        margin-bottom: 23px; }

      > a {
        @extend .map-place-card__link; } } } }

.link {
  @extend %text-upper-md;
  border-bottom: 1px solid currentColor;

  @include tr(color .3s);

  &[href] {
    @include hover-focus {
      color: $dark; } } }
