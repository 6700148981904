.btn {
  $this: &;

  @extend %text-upper-md;

  line-height: 1.4;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: $c-text;
  border: 1px solid $c-text;
  background-color: transparent;
  border-radius: $radius;

  min-width: 215px;

  padding: 15px 15px 14px;

  @include sm {
    min-width: 180px;
    padding: 14px 10px 13px; }

  @include tr(background-color .3s, border-color .3s, color .3s, opacity .3s);

  @include hover-focus {
    &:not(#{$this}--no-hover) {
      background-color: $c-text;
      color: $white;
      border-color: $c-text; } }


  @include before {
    display: inline-block;
    border-radius: 50%;

    width: 7px;
    height: 7px;

    background-color: currentColor;

    margin-right: em(15);

    @include sm {
      margin-right: em(8, $base-fz-sm);
      width: 6px;
      height: 6px; } }

  &--white {
    color: $white;
    border-color: $white;
    @include hover-focus {
      &:not(#{$this}--no-hover) {
        background-color: $white;
        border-color: $white;
        color: $c-text; } }

    &#{$this}--filled {
      @include hover-focus {
        &:not(#{$this}--no-hover) {
          background-color: $c-text;
          border-color: $c-text;
          color: $white; } } } }

  &--simple {
    @include before {
      display: none; } }

  &--filled {
    background-color: $white;
    border-color: $white;

    color: $c-text;

    @include hover-focus {
      &:not(#{$this}--no-hover) {
        background-color: darken($white, 30%);
        border-color: darken($white, 30%);

        color: $c-text; } } }

  &--filled-dark {
    border-color: $black-light;
    background-color: $black-light;

    color: $white;
    @include hover-focus {
      &:not(#{$this}--no-hover) {
        background-color: lighten($black-light, 30%);
        border-color: lighten($black-light, 30%);

        color: $white; } } }

  &--filled-light {
    border-color: $light;
    background-color: $light;

    color: $c-text;
    @include hover-focus {
      &:not(#{$this}--no-hover) {
        background-color: darken($light, 30%);
        border-color: darken($light, 30%);

        color: $c-text; } } }

  &--full {
    min-width: 0;
    width: 100%; }

  &--lg {
    font-size: 1em;
    padding: 17px 15px 16px;
    @include before {
      display: none; } }

  &--sm {
    @extend %letter-spacing;

    font-weight: 400;
    font-size: 13px;

    padding: 13px 15px 12px;
    min-width: 205px;

    @include xl {
      font-size: 12px;
      min-width: 193px;
      padding: 14px 15px 13px; }

    @include sm {
      min-width: 180px;
      font-size: 12px;
      padding: 12px 10px 11px; }

    @include before {
      margin-bottom: .1em; } }

  &--xs {
    min-width: 132px;
    @include before {
      display: none; } } }

.btn-link {
  @extend %letter-spacing;

  display: inline-flex;
  align-items: center;

  border-bottom: 2px solid currentColor;
  text-transform: uppercase;
  font-size: 16px;

  padding: 7px 10px 7px 0;
  min-width: 237px;

  @include xl {
    min-width: 226px; }

  @include sm {
    font-size: 15px; }

  @include before {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: currentColor;
    margin-right: em(15, 16); } }
