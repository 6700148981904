.toggle-list {
  $this: &;

  @extend %text-upper-sm;

  display: flex;
  align-items: center;

  margin-left: rem(-14);
  margin-right: rem(-14);

  &__item {
    padding-left: rem(14);
    padding-right: rem(14);
    color: rgba($c-text, .5);

    #{$check-inputs} {
      position: absolute;
      @include hidden; }

    label {
      position: relative;
      cursor: pointer; }

    @include notlast {
      border-right: 1px solid $c-text; }

    &--current {
      color: $c-text; } }

  &__link {
    @include tr(opacity .3s);
    @include hover-focus {
      opacity: .7; } }


  &--white {
    #{$this}__item {
      color: rgba($white, .7);
      border-color: $white;

      &--current {
        color: $white; } } } }

