.form {
  $this: &;

  &__control-test {
    overflow: hidden;
    position: relative;
    width: 0;
    height: 0;

    input,
    label {
      position: absolute;
      opacity: 0; } }

  &__field {
    @include notlast {
      margin-bottom: 48px;
      @include md {
        margin-bottom: 33px; } }

    &--auxiliary {
      &[data-state="hidden"] {
        display: none !important; } } }

  &__col {
    @extend #{$this}__field; }

  &__block {
    #{$this}__field {
      @include notlast {
        margin-bottom: 15px; }
      &--row {
        @include r(413) {
          @include notlast {
            margin-bottom: 0; } } } }

    @include notlast {
      margin-bottom: 35px; } }

  &__btn {
    margin-top: 65px;

    @include md {
      margin-top: 55px; }

    @include sm {
      margin-top: 40px; } } }

.order-block {
  background-color: $white;
  padding: 34px;
  border-radius: $radius;

  @include sm {
    padding: 20px; }

  &__title {
    @extend %text-upper-md;

    margin-bottom: 32px; }

  &__list {
    @extend %row;
    align-items: center;

    margin-left: -34px;
    margin-right: -34px;

    padding-left: 34px;
    padding-right: 34px;

    border-bottom: 1px solid rgba($dark, .24);

    margin-bottom: 28px;

    dt,
    dd {
      margin-bottom: 20px; }

    dd {
      @extend %text-upper;
      width: 100px;
      text-align: right; }


    dt {
      font-size: 16px;
      font-weight: 400;
      width: calc(100% - 100px); } }

  &__price-field,
  &__field {
    @extend %row;
    justify-content: space-between;
    align-items: center; }

  &__field {
    margin-bottom: 35px;
    small {
      font-size: 16px;
      color: rgba($dark, .7); }

    a {
      text-decoration: underline;
      color: rgba($c-text, .7);
      font-size: 14px; } }

  &__price-field {
    margin-bottom: 49px; }

  &__price-title {
    @extend %text-upper-md; }

  &__price {
    @extend %text-upper-lg-l; }

  &__btn {
    margin-top: 71px; } }
