.out {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  padding-top: $header-height;

  @include xl {
    padding-top: $header-height-xl; }

  @include sm {
    padding-top: $header-height-sm; }

  @include xs {
    padding-bottom: $side-controls-height-xs; }

  .is-offline & {
    padding-top: $offline-panel-height + $header-height;

    @include xl {
      padding-top: $offline-panel-height + $header-height-xl; }

    @include sm {
      padding-top: $offline-panel-height + $header-height-sm; }

    @include xs {
      padding-bottom: $offline-panel-height + $side-controls-height-xs; } }

  &__header {
    position: fixed;
    z-index: $z-index-controls;
    top: 0;
    left: 0;
    right: 0;

    .is-offline & {
      top: $offline-panel-height; } }

  &__controls {
    position: fixed;
    z-index: $z-index-controls + 1;

    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    @include xs {
      top: auto;
      transform: none;
      left: 0;
      bottom: 0; } }

  &__main {
    flex-grow: 1;
    @include tr(transform .4s $easeInSine); }

  &__offline-panel {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: $z-index-controls + 1; } }

