.side-controls {
  $this: &;

  @extend %text-upper;

  color: $white;
  width: $side-controls-size;

  @include tr(color .3s);

  @include xs {
    width: auto;
    display: flex;
    background-color: $white; }

  &__item {
    @include xs {
      @include notlast {
        width: calc(#{percentage(1 / 4)} - 1px); }
      &:last-child {
        width: percentage(1 / 4); } }

    @include notlast {
      margin-bottom: 1px;
      @include xs {
        margin-bottom: 0;
        margin-right: 1px; } } }

  &__link {
    display: inline-flex;
    align-items: center;
    text-align: left;

    padding: 16px;
    height: $side-controls-size;

    background-color: $black-light;

    @include xsmin {
      @include loaded {
        @include tr(transform .4s $easeInSine); }

      @include hover-focus {
        transform: translate(calc(-100% + #{$side-controls-size}), 0);

        #{$this}__title {
          opacity: 1; } } }

    @include xs {
      width: 100%;
      height: $side-controls-height-xs;
      text-align: center;
      justify-content: center; } }

  &__icon {
    font-size: 1.3em;
    flex-shrink: 0; }

  &__title {
    opacity: 0;
    margin-left: 11px;
    white-space: nowrap;

    @include tr(opacity .4s);

    @include xs {
      display: none; } }

  &--light {
    color: inherit;

    #{$this}__link {
      background-color: $lightest; } } }
