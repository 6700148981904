.table {
  $this: &;

  td,
  th {
    padding: 12px;
    vertical-align: middle;
    border: 1px solid $lightest; }

  th {
    border: 1px solid $light;
    background-color: $light;
    font-weight: 700;
    text-align: left; }

  tr {
    @include tr(background-color .3s); }

  &__foot {
    margin-bottom: 30px; }

  &__btn {
    margin-bottom: 50px;
    @include notlast {
      margin-right: 30px; } }

  &__body {
    padding-top: 30px;
    padding-bottom: 30px; }

  &__wrap {
    overflow: auto; }

  &--static {
    @include xs {
      width: 576px; } }

  &--light {
    td,
    th {
      border: 0;
      background-color: transparent; }

    th {
      color: $white; }

    tr {
      &:nth-child(odd) {
        background-color: rgba($lightest, .3); } } }

  &--faq,
  &--main {
    tr {
      @include hover {
        background-color: $light; } } }

  &--faq {
    td,
    th {
      @include smmin {
        &:nth-child(1) {
          width: 20%; }

        &:nth-child(2) {
          width: 80%; } } }

    tr {
      @include sm {
        display: block;
        margin-bottom: 15px; } }

    td {
      @include sm {
        display: block; }

      &:nth-child(1) {
        border-right-color: transparent;
        @include sm {
          border-right-color: $lightest;
          border-bottom-color: transparent; } } } }

  &--main {
    th,
    td {
      min-width: 50px;
      max-width: 80px; }

    td {
      &:first-child {
        min-width: 260px; } }

    #{$this}__title,
    #{$this}__tabs {
      @extend .section__subttl;

      @media print {
        display: none; } }

    #{$this}__table-wrap {
      overflow: auto;
      margin-bottom: 30px;

      &:not([data-state="rendered"]) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50vh; } }

    #{$this}__table {
      // tbody
      //   > tr
      //     > td
      //         &:only-child
      //           padding-top: 30px
      //           padding-bottom: 30px
      //     &:first-child
      //       > td
      //         &:only-child
 } } }      //           height: 265px
