.fancy-img-block {
  position: relative;
  padding-top: 99px;
  // height: vw(826)

  height: 826px;
  max-height: 826px;

  @include xl {
    padding-top: 0;
    height: vw(826); }

  @include lg {
    height: 500px; }

  @include md {
    height: auto; }

  &__img-wrap {
    position: absolute;
    top: 99px;
    bottom: 0;
    left: 0;
    font-size: 0;

    width: percentage(1163 / $base-desktop);
    max-width: 1163px;

    @include xl {
      top: 0; }

    @include md {
      position: static;
      width: calc(100% - #{$gap-container * 2 + px});
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px; } }

  &__img {
    @extend %coverimage; }

  &__img-sm {
    object-fit: cover;

    position: absolute;

    top: -99px;
    right: -360px;
    width: 548px;
    height: 292px;

    @include xl {
      display: none; } }


  &__right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: 100%;

    @include md {
      display: block;
      height: auto; } }

  &__content {
    width: percentage(520 / $base-container);
    margin-left: auto;
    margin-bottom: 8%;

    @include md {
      width: 100%;
      margin-bottom: 0; } } }


