.footer {
  $this: &;

  &__main {
    background-color: $black-light;
    color: $white; }

  &__block {
    @include notlast {
      border-bottom: 1px solid rgba($white, .24); }

    &:nth-child(1) {
      padding: 75px 0;
      @include sm {
        padding: 70px 0 38px; } }

    &:nth-child(2) {
      padding: 40px 0;

      #{$this}__left {
        @include sm {
          margin-bottom: 30px; } }

      @include sm {
        padding: 20px 0; } } }

  &__inner {
    @extend %row; }

  &__nav {
    &.nav--vert {
      font-size: 10px; } }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: percentage(550 / $base-container);
    padding-right: 30px;

    @include xl {
      width: 30%;
      padding-right: 20px; }

    @include lg {
      flex-direction: row;
      align-items: center;

      width: 100%;
      padding-right: 0;
      margin-bottom: 55px; } }

  &__right {
    @extend %row;
    justify-content: space-between;

    width: percentage(($base-container - 550) / $base-container);

    @include xl {
      width: 70%; }

    @include lg {
      width: 100%; }

    #{$this}__nav {
      padding-right: 15px;
      @include sm {
        margin-bottom: 38px; }

      &:nth-child(1) {
        width: 35%;
        @include sm {
          width: 100%;
          padding-right: 0; } }

      &:nth-child(2) {
        width: 25%;
        @include sm {
          width: 50%; } }

      &:nth-child(3) {
        width: 18%;
        @include sm {
          width: 50%; } }

      &:nth-child(4) {
        width: 100% - 18% - 25% - 35%;
        @include sm {
          width: 100%;
          padding-right: 0;
          margin-bottom: 0;
          .nav__list {
            display: flex; }
          .nav__item {
            width: 50%;
            margin-bottom: 0; } } }



      &.nav--md {
        width: 100%;
        @include sm {
          margin-bottom: 0; }

        .nav__item {
          @include sm {
            width: 50%;
            padding-right: 15px;
            margin-bottom: 20px;
            margin-right: 0; } } } } }

  &__logo {
    margin-bottom: 72px;
    @include lg {
      margin-bottom: 0; } }

  &__btn {
    margin-bottom: 32px;
    @include lg {
      margin-bottom: 0; } }

  &__text-block {
    @include lg {
      display: none; } }

  &__text-block-title {
    @extend %letter-spacing;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 23px; }

  &__text {
    max-width: 412px;
    line-height: 1.8;
    margin-bottom: 29px; }

  &__footer {}

  &__footer-in {
    @extend %row;
    align-items: center;

    padding-top: 8px;
    padding-bottom: 8px;

    @include sm {
      justify-content: center;
      text-align: center;
      padding-top: 22px;
      padding-bottom: 22px; } }

  &__social {
    @include sm {
      order: 0;
      width: 100%;
      margin-bottom: 25px; } }

  &__copy {
    @extend %text-upper-sm;
    @extend #{$this}__left;
    display: block;

    @include xl {
      width: percentage(471 / $base-container-xl);
      margin-bottom: 0; }

    @include sm {
      width: 100%;
      order: 1;
      display: block;
      margin-bottom: 25px; } }

  &__auth {
    margin-left: auto;
    @include sm {
      order: 2;
      margin-left: 0; } } }
