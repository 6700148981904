.sitemap {
  // @extend %row
  @extend %clr;
  margin-left: -15px;
  margin-right: -15px;

  margin-bottom: -50px;

  &__block-title {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: rgba($c-text, .5);
    margin-bottom: 30px;

    a {
      @extend .nav__link; } }

  &__block {
    float: left;
    width: percentage(1 / 3);
    padding-left: 15px;
    padding-right: 15px;

    margin-bottom: 50px;

    @include md {
      width: 50%; }

    @include sm {
      width: 100%; } } }
