.cards {
  $this: &;

  @extend %row;

  margin-left: -15px;
  margin-right: -15px;

  margin-bottom: -97px;

  @include lg {
    margin-bottom: -50px; }

  &__item {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 97px;

    @include lg {
      margin-bottom: 50px; }

    @include sm {
      width: 100%; }

    .card {
      max-width: 548px; } }

  &--sm {
    #{$this}__item {
      width: percentage(1 / 3);

      @include sm {
        width: 100%; }

      .card {
        max-width: 457px;
        @include sm {
          max-width: none; } } } } }

.card {
  $this: &;

  display: block;

  &__img {
    display: block;
    position: relative;
    background-color: $dark;
    padding-top: percentage(292 / 548);

    img {
      @extend %coverdiv;
      @extend %coverimage; } }

  &__content {
    display: block;
    padding-top: 45px; }

  &__upttl {
    display: block;
    font-size: 17px;
    letter-spacing: .15em;
    color: rgba($white, .7);
    text-transform: uppercase;
    margin-bottom: 16px;

    @include sm {
      font-size: 14px; } }

  &__title {
    display: block;
    margin-bottom: 23px;

    &:not(.title) {
      @extend %text-upper-lg; } }

  &--sm {
    #{$this}__content {
      @include sm {
        padding-top: 19px; } }

    #{$this}__upttl {
      margin-bottom: 14px; }

    #{$this}__title {
      margin-bottom: 14px; } } }


