.input {
  $this: &;

  input,
  select {
    &:disabled {
      opacity: 0.5; } }

  &:not(#{$this}--border) {
    #{$text-inputs} {
      @extend %text-upper;

      width: 100%;
      height: 32px;
      padding: .3rem 0;
      border-bottom: 1px solid $black;

      @include placeholder {
        color: inherit; } }

    textarea {
      min-height: 79px;
      height: auto; } }

  &__label {
    &--error {
      color: $danger !important;
      font-size: 0.8em !important; } }

  &--select {
    select {
      color: inherit; }

    &#{$this}--border {
      position: relative;
      color: rgba($c-text, .3);

      &::before,
      &::after {
        content: '';
        position: absolute;
        pointer-events: none;
        right: rem(16); }

      &::before {
        @include arr(10px, 6px, currentColor, t);
        top: 36px; }

      &::after {
        @include arr(10px, 6px, currentColor, b);
        top: 46px; }

      &#{$this}--has-text {
        color: inherit; } } }

  &--border {
    text-align: left;

    &#{$this}--has-text {
      #{$text-inputs},
      select,
      textarea {
        border-color: $black; } }

    #{$text-inputs},
    select,
    textarea {
      @include tr(border-color .3s);

      &:focus {
        border-color: $black; } }

    #{$text-inputs},
    select {
      width: 100%;
      border: 1px solid rgba($c-text, .3);
      background-color: transparent;
      border-radius: 0;
      height: 46px;
      font-weight: 700;
      letter-spacing: 0.04em;
      padding: .3rem rem(16);

      @include placeholder {
        color: rgba($c-text, .3); } }

    textarea {
      min-height: 106px;
      height: auto; }

    select {
      appearance: none; }

    #{$this}__label {
      @extend  %text-upper-sm;
      font-weight: 700;
      text-transform: none;
      color: rgba($c-text, .3);

      display: block;
      margin-bottom: 4px; } } }


.custom-select {
  $this: &;

  position: relative;

  select {
    position: absolute;
    @include hidden; }

  &__opener {
    @extend %text-overflow;

    position: relative;

    padding-right: 15px;
    cursor: pointer;

    @include after {
      @include icon-arrow(8, 8, 2, currentColor, 225, px);
      display: inline-block;

      position: absolute;
      top: 40%;
      right: 2px;
      transform: translate(0, -50%) rotate(-45deg); } }

  &__option {
    cursor: pointer; }

  &__panel {
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0;
    @include loaded {
      @include tr(opacity .3s, visibility .3s); }
    @include hidden; }

  &--open {
    #{$this}__panel {
      @include visible; } } }

.select-text {
  @extend %text-upper;
  .custom-select {}

  &--sm {
    @extend %text-upper-sm; } }
