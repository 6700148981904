@mixin mb-section {
  margin-bottom: 140px;

  @include xl {
    margin-bottom: 100px; }

  @include lg {
    margin-bottom: 70px; } }

.overflow-hidden {
  overflow: hidden; }

.p-0 {
  padding: 0 !important; }
.pt-0 {
  padding-top: 0 !important; }
.pb-0 {
  padding-bottom: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }
.mb-0 {
  margin-bottom: 0 !important; }

.mb-section {
  @include mb-section;

  &--smmin {
    @include smmin {
      @include mb-section; } } }

.full-width {
  width: 100%;

  &--fancy {
    @include xxs {
      display: block;
      width: calc(100% + #{$gap-container * 2 + px});
      max-width: none;

      margin-left: -$gap-container + px;
      margin-right: -$gap-container + px; } } }

.text {
  &--center {
    text-align: center; } }

.color {
  &--gold {
    color: $gold; } }
