.table-tabs {
  @extend %row;

  @include sm {
    flex-wrap: nowrap;
    overflow: auto; }

  &__tab {
    @extend .btn;
    @extend .btn--simple;
    @extend .btn--filled-dark;

    font-size: 1em;
    min-width: 100px;
    padding: 10px;

    margin-right: 5px;
    margin-bottom: 5px;

    &[data-state="active"] {
      background-color: lighten($black-light, 30%);
      border-color: lighten($black-light, 30%);

      color: $white; } } }

.tabs {
  @include r($base-mobile) {
    display: flex;
    flex-direction: column; }

  &__img-wrap {
    @include r($base-mobile) {
      order: 0; } }

  &__tabs {
    @include r($base-mobile) {
      order: 1; } }

  &__content {
    @include r($base-mobile) {
      order: 2; } }

  &__tabs {
    display: flex;
    // align-items: center
    justify-content: space-between;
    margin-bottom: 41px;

    @include xl {
      margin-bottom: 27px; } }

  &__tab {
    @extend %text-upper-lg;

    width: percentage(1 / 3);

    padding: 9px 0;

    text-align: center;
    color: rgba($white, .6);

    border-bottom: 4px solid rgba($white, .12);

    @include tr(opacity .3s);

    @include hover {
      opacity: $opacity-hover; }

    @include lg {
      font-size: 13px; }

    @include xxs {
      font-size: 11px; }

    &:first-child {
      text-align: left; }

    &:last-child {
      text-align: right; }

    &[data-state="active"] {
      border-bottom-color: $white;
      color: $white; } }

  &__img {
    display: none;

    &[data-state="active"] {
      display: block;
      animation: fade-in 1s; } }

  &__item {
    @extend %text;

    display: none;

    &[data-state="active"] {
      display: block;
      animation: fade-in 1s; } } }
