.social {
  @extend %text-upper-sm;

  display: flex;
  align-items: center;

  @include sm {
    justify-content: center; }

  &__item {
    @include sm {
      margin-left: 25px;
      margin-right: 25px; }

    @include notlast {
      margin-right: 50px;

      @include sm {
        margin-right: 25px; } } } }
