.banner {
  position: relative;
  background-color: $dark;
  color: $white;

  &__bg {
    @extend %coverdiv;
    @extend %coverimage;
    z-index: 1; }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;
    z-index: 1;
    min-height: vw(728);

    padding-top: 70px;
    padding-bottom: 70px;

    @include sm {
      padding-top: 60px;
      padding-bottom: 60px; } }

  &__content {
    max-width: 550px; }

  &__title,
  &__title-block {
    margin-bottom: 23px;
    @include sm {
      margin-bottom: 14px; } }

  &__title-block {
    .title-block__upttl {
      color: rgba($white, .7); } }

  &__text {
    margin-bottom: 45px;
    @include sm {
      margin-bottom: 30px; } }

  &__btns {
    .btn {
      margin-bottom: 10px;
      @include notlast {
        margin-right: 15px; } } } }
