.s-half {
  $this: &;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    min-height: 788px;

    @include xl {
      min-height: 581px; } }

  &__content-wrap,
  &__title-block {
    width: percentage(795 / $base-container);
    margin-left: percentage(710 / $base-container);
    padding-left: percentage(($base-container - 795 - 710) / $base-container);

    @include xl {
      width: percentage(570 / $base-container-xl);
      margin-left: percentage(($base-container-xl - 570) / $base-container-xl);
      padding-left: 0; }

    @include lg {
      width: 50%;
      margin-left: 50%;
      padding-left: 20px; }

    @include md {
      width: 100%;
      margin-left: 0;
      padding-left: 0; } }

  &__title-block {
    width: percentage(($base-container - 710) / $base-container);
    margin-bottom: 40px;

    @include xl {
      width: percentage(570 / $base-container-xl);
      margin-left: percentage(($base-container-xl - 570) / $base-container-xl);
      margin-bottom: 20px; }

    @include lg {
      width: 50%;
      margin-left: 50%;
      padding-left: 20px; }

    @include md {
      width: 100%;
      margin-left: 0;
      padding-left: 0; } }

  &__content {
    @extend %text;

    .btn {
      // margin-top: 20px
      @include notlast {
        margin-bottom: 25px; } }

    h2 {
      margin-bottom: 20px; }

    h3, h4 {
      @extend .text-block__title; }

    p {
      &:not(:last-child):not(.s-half__text):not(.text-block__title) {
        margin-bottom: 50px;
        @include xl {
          margin-bottom: 18px; }
        @include md {
          margin-bottom: 48px; } } }

    ul {
      @extend .list;
      @extend .text-block__text; } }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: percentage(710 / $base-container);
    font-size: 0;
    background-color: $dark;

    @include xl {
      width: percentage(523 / $base-container-xl); }

    @include lg {
      width: 50%; }

    @include md {
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      width: 100%;
      max-width: $base-mobile + px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 33px; }

    @include r($base-mobile) {
      padding-top: 108%; }

    img {
      @extend %coverimage;
      @include r($base-mobile) {
        @include coverdiv; } } }

  &__text {
    @extend %text;

    margin-bottom: 57px;

    @include xl {
      margin-bottom: 26px; }

    @include sm {
      margin-bottom: 21px; } }

  &--reverse {
    #{$this}__content-wrap,
    #{$this}__title-block {
      padding-left: percentage(154 / $base-container);
      margin-left: 0;

      @include xl {
        padding-left: 0; }

      @include lg {
        padding-right: 20px; }

      @include md {
        padding-right: 0; } }

    #{$this}__img {
      right: 0;
      left: auto; } } }

.s-news {
  padding-bottom: 0;

  &__title-block {
    margin-bottom: 42px;

    @include xl {
      margin-bottom: 28px; } }

  &__posts {
    padding-bottom: 30px;
    position: relative;

    @include xl {
      padding-bottom: 5px; }

    @include md {
      padding-bottom: 0; }

    @include before {
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      border-bottom: 4px solid $black;

      @include xl {
        left: 10px;
        right: 10px; }

      @include md {
        display: none; } } } }

.s-contacts {
  &__inner {
    position: relative;
    min-height: 589px;

    @include md {
      min-height: 0; }

    @include notlast {
      margin-bottom: 92px;

      @include xl {
        margin-bottom: 51px; }

      @include md {
        margin-bottom: 42px; } } }

  &__title-block,
  &__text,
  &__select,
  &__addresses {
    width: percentage(609 / $base-container);
    padding-right: 30px;

    @include xl {
      width: percentage(492 / $base-container-xl);
      padding-right: 20px; }

    @include md {
      width: auto;
      padding-right: 0; } }

  &__title-block {
    margin-bottom: 30px; }

  &__text {
    margin-bottom: 49px;
    @include md {
      margin-bottom: 46px; } }

  &__select {
    margin-bottom: 90px;
    margin-right: 30px;
    width: calc(#{percentage(609 / $base-container)} - 30px);

    @include xl {
      margin-bottom: 57px;
      width: calc(#{percentage(492 / $base-container-xl)} - 30px); }

    @include md {
      margin-bottom: 30px;
      margin-right: 0;
      width: 100%; } }

  &__addresses {
    display: block;
    @include xl {
      display: flex;
      align-items: flex-start; }
    @include xs {
      display: block; } }

  &__address {
    max-width: 520px;

    &.address {
      > label {
        @extend %row;
        width: 100%;

        position: relative;
        cursor: pointer;

        @include xl {
          display: block; }

        #{$check-inputs} {
          position: absolute;
          @include hidden; } } }

    @include xl {
      @include notlast {
        padding-right: 30px; } }

    @include xs {
      @include notlast {
        padding-right: 0; } }

    @include notlast {
      margin-bottom: 60px;
      @include xl {
        margin-bottom: 0; }
      @include xs {
        margin-bottom: 38px; } } }

  &__map {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: percentage(($base-container - 609) / $base-container);
    background-color: $dark;

    @include xl {
      width: percentage(($base-container-xl - 492) / $base-container-xl); }

    @include md {
      position: relative;
      top: auto;
      right: auto;
      width: auto;
      padding-top: percentage(300 / $base-mobile);
      margin-bottom: 30px; }

    @include xxs {
      margin-left: -$gap-container + px;
      margin-right: -$gap-container + px; } } }

.s-questions {
  &__inner {
    @extend %row; }

  &__left,
  &__right {
    width: 50%;

    @include md {
      width: 100%; } }

  &__left {
    padding-right: 15px;
    @include md {
      padding-right: 0;
      margin-bottom: 35px; } }

  &__right {
    padding-left: 15px;
    @include md {
      padding-left: 0; } }

  &__title-block {
    margin-bottom: 32px; }

  &__text {
    @extend %text;

    max-width: 688px; }

  &__form {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    @include md {
      max-width: none; } } }

.s-order {
  text-align: center;

  &__inner {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto; }

  &__title {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22px; }

  &__subttl {
    margin-bottom: 41px; } }

.s-sitemap {
  &__block {
    margin-bottom: 65px;

    @include xl {
      margin-bottom: 50px; }

    @include sm {
      margin-bottom: 35px; } } }

.s-error {
  text-align: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
    min-height: calc(100vh - #{$header-height});

    padding-top: 120px;
    padding-bottom: 120px;

    position: relative;

    @include xl {
      min-height: calc(100vh - #{$header-height-xl}); }

    @include sm {
      min-height: 0;
      display: block; } }


  &__title,
  &__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 657px; }

  &__img {
    margin-bottom: 52px; }

  &__title {
    margin-bottom: 21px; }

  &__text {
    margin-bottom: 85px; }

  &__nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120px;

    @include sm {
      position: static; } } }

